import { makeStyles } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import React from "react"

import Theme from "@/entity/theme/theme"

const useStyles = makeStyles(() => ({
    font: {
        fontFamily: Theme.font.fontFamily,
        fontSize: "13px",
    },
}))

type SortDisplaySwitchProps = {
    isSortDisplay: boolean
    setIsSortDisplay: (v: boolean) => void
}

const SortDisplaySwitch: React.FC<SortDisplaySwitchProps> = ({
    isSortDisplay,
    setIsSortDisplay,
}) => {
    const classes = useStyles()

    const handleChange = () => {
        setIsSortDisplay(!isSortDisplay)
    }

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isSortDisplay}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                />
            }
            label={<span className={classes.font}>絞り込み</span>}
        />
    )
}

export default SortDisplaySwitch
