import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Chip from "@material-ui/core/Chip"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import React from "react"

import Theme from "@/entity/theme/theme"

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        height: theme.spacing(3),
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: Theme.font.fontFamily,
    },
    unSelected: {
        backgroundColor: theme.palette.grey[100],
        "&:hover, &:focus": {
            backgroundColor: theme.palette.grey[100],
        },
    },
    selected: {
        backgroundColor: Theme.color.thirdColor,
        color: "#fff",
        "&:hover, &:focus": {
            backgroundColor: Theme.color.thirdColor,
        },
    },
}))

const SelectPickerButtons = React.memo((props) => {
    const classes = useStyles(props)
    const { selectedPicker, setSelectedPicker, setRangeDate } = props

    const selectBreadcurmb = (selected) => {
        setSelectedPicker(selected)
        switch (selected) {
            case "day":
                setRangeDate({
                    startDate: moment().startOf("day"),
                    endDate: moment().startOf("day"),
                })
                break
            case "month":
                setRangeDate({
                    startDate: moment().startOf("month"),
                    endDate: moment().endOf("month"),
                })
                break
            case "custom":
                setRangeDate({
                    startDate: moment(moment().startOf("day").subtract(7, "d")).startOf("day"),
                    endDate: moment().startOf("day"),
                })
                break
        }
    }

    const RenderBlock = () => {
        return (
            <Breadcrumbs aria-label="カレンダー選択" className={classes.breadcrumbs}>
                <Chip
                    label="日"
                    onClick={() => selectBreadcurmb("day")}
                    className={selectedPicker == "day" ? classes.selected : classes.unSelected}
                />
                <Chip
                    label="月"
                    onClick={() => selectBreadcurmb("month")}
                    className={selectedPicker == "month" ? classes.selected : classes.unSelected}
                />
                <Chip
                    label="カスタム"
                    onClick={() => selectBreadcurmb("custom")}
                    className={selectedPicker == "custom" ? classes.selected : classes.unSelected}
                />
            </Breadcrumbs>
        )
    }

    return <RenderBlock />
})

export default SelectPickerButtons

// import { makeStyles } from "@material-ui/core/styles"
// import ToggleButton from "@material-ui/lab/ToggleButton"
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
// import moment from "moment"
// import React from "react"

// import Theme from "@/entity/theme/theme"

// const useStyles = makeStyles((theme) => ({
//     breadcrumbs: {
//         height: "30px",
//         fontWeight: theme.typography.fontWeightRegular,
//         fontFamily: Theme.font.fontFamily,
//     },
//     unSelected: {
//         backgroundColor: theme.palette.grey[100],
//         "&:hover, &:focus": {
//             backgroundColor: theme.palette.grey[100],
//         },
//     },
//     selected: {
//         backgroundColor: Theme.color.thirdColor,
//         color: "#fff",
//         "&:hover, &:focus": {
//             backgroundColor: Theme.color.thirdColor,
//         },
//     },
// }))

// const PickerSelectToggleButtons = React.memo((props) => {
//     const classes = useStyles(props)
//     const { selectedPicker, setSelectedPicker, setRangeDate } = props
//     console.log({ selectedPicker, setSelectedPicker, setRangeDate })

//     const selectPicker = (event, value) => {
//         setSelectedPicker(value)
//         switch (value) {
//             case "day":
//                 setRangeDate({
//                     startDate: moment().startOf("day"),
//                     endDate: moment().startOf("day"),
//                 })
//                 break
//             case "month":
//                 setRangeDate({
//                     startDate: moment().startOf("month"),
//                     endDate: moment().endOf("month"),
//                 })
//                 break
//             case "custom":
//                 setRangeDate({
//                     startDate: moment(moment().startOf("day").subtract(7, "d")).startOf("day"),
//                     endDate: moment().startOf("day"),
//                 })
//                 break
//         }
//     }

//     return (
//         <ToggleButtonGroup
//             value={selectedPicker}
//             onChange={selectPicker}
//             aria-label="ピッカー選択"
//             exclusive
//             className={classes.breadcrumbs}
//         >
//             <ToggleButton
//                 className={selectedPicker == "day" ? classes.selected : classes.unSelected}
//                 value="day"
//                 aria-label="日ピッカー"
//                 size="small"
//                 selected={selectedPicker == "day"}
//             >
//                 日
//             </ToggleButton>
//             <ToggleButton
//                 value="month"
//                 aria-label="月ピッカー"
//                 size="small"
//                 selected={selectedPicker == "month"}
//                 className={selectedPicker == "month" ? classes.selected : classes.unSelected}
//             >
//                 月
//             </ToggleButton>
//             <ToggleButton
//                 value="custom"
//                 aria-label="カスタムピッカー"
//                 size="small"
//                 selected={selectedPicker == "custom"}
//                 className={selectedPicker == "custom" ? classes.selected : classes.unSelected}
//             >
//                 カスタム
//             </ToggleButton>
//         </ToggleButtonGroup>
//     )
// })

// export default PickerSelectToggleButtons
