import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import Theme from "../../entity/theme/theme"

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "row",
        "@media screen and (max-width:350px) ": {
            width: "96px",
        },
    },
    font: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
        fontSize: "13px",
    },
}))

export default function GenderCheckBox(props) {
    const classes = useStyles()
    const { checked, genderCheckBoxClick } = props

    return (
        <FormGroup className={classes.root}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked.man}
                        onChange={genderCheckBoxClick("man")}
                        value="man"
                        color="primary"
                        size="small"
                    />
                }
                label={<Typography className={classes.font}>男子</Typography>}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked.woman}
                        onChange={genderCheckBoxClick("woman")}
                        value="woman"
                        color="primary"
                        size="small"
                    />
                }
                label={<Typography className={classes.font}>女子</Typography>}
            />
        </FormGroup>
    )
}
